<template>
    <div class="videoView">
        <Artplayer @get-instance="getInstance" :option="artplayerObj.option" :style="artplayerObj.style" :mediaInfo="artplayerObj.info"/>
    </div>
</template>
 
<script>
// import {
//   getUserCourseDetail,// 获取课程内容
// } from "../../request/api/home.js";
import Artplayer from "./components/Artplayer.vue";
import axios from 'axios';
export default {
    components: { Artplayer },
    data() {
        return {
            // 视频对象
            artplayerObj: {
                option: {
                    url: '',
                    type: 'video',
					poster: '',
					pip: false,
					flip:true,
                },
				icons: {
					state: '<img width="150" heigth="150" src="/assets/img/state.svg">',
				},
                style: {
                    height: "40vh",
                },
				info: {},
            },
			videoId:'',
        }
    },
    methods: {
        async ajaxData(id) {
			axios.get("https://xqt.juexingongshe.com/user/course/detail?taskId="+id).then((response=>{
				const res = response.data;
				console.log("----succ--",res);
				if (res.code === 200) {
					// info['type']=res.data.type,
					// info['dataId']=res.data.dataId,
					// info['taskId']=res.data.taskId,
					// info['point']=res.data.point,
					// info['isFinished']=0
					this.artplayerObj.info = {
					    type :res.data.type,
					    dataId : res.data.dataId,
					    taskId :res.data.taskId,
					    point:res.data.point
					}
					this.artplayerObj.option = {
					    url: "https://xtqcdn.juexingongshe.com/oss/"+res.data.path,
						poster:"https://xtqcdn.juexingongshe.com/oss/"+res.data.picturePath,
					}
					document.title = res.data.name;
					// uni.setNavigationBarTitle({
					// 	title:res.data.name
					// })
				}
			}));
			
// const res = await getUserCourseDetail(id);
			
			// console.log("----",res.data)
        },
        getInstance(art) {
            console.info(art);
        },
    },
    computed: {
    },
	onLoad() {},
    mounted() {
		// const route = useRoute();
		// console.log('当前路径：', route);
		var currentUrl = window.location.href;
		
		var url = new URL(currentUrl);
		// 使用 URLSearchParams 获取参数对象
		var params = new URLSearchParams(url.search);
		// 获取 id 参数的值
		var id = params.get('id');
		console.log("-----log--",id)
        this.ajaxData(id)
    }
};
</script>

<style >
	.videoView {
		margin-top: 40%;
	}
	/* .art-progress {
	    pointer-events: none !important;
	} */
</style>