<template>
    <div ref="artRef"></div>
</template>
  
<script>
import Artplayer from "artplayer";
import axios from 'axios';
import wx from 'weixin-js-sdk'
let Hls = require('hls.js');

function playM3u8(video, url, art) {
    if (Hls.isSupported()) {
        if (art.hls) art.hls.destroy();
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(video);
        art.hls = hls;
        art.on('destroy', () => hls.destroy());
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = url;
    } else {
        art.notice.show = 'Unsupported playback format: m3u8';
    }
}
export default {
    data() {
        return {
            instance: null,
			currentTime:0, //当前时间
			duration:0,//视频时长
			media:{},
			isMoment:0,
			seek:0,
        };
    },
    props: {
        option: {
            type: Object,
            required: true,
        },
		mediaInfo: {
		    type: Object,
		    required: true,
		},
    },
    methods: {
		//提交学习时长
		async ajaxData() {
			let cTime = Math.floor(this.currentTime);
			let dTime = Math.floor(this.duration);
			this.media['point'] = cTime;
			this.media['isFinished'] = 0;
			if (cTime === dTime) {
				this.media['isFinished'] = 1;
				this.media['point'] = 0;
				this.isMoment = 1;
			}
			this.media['dataId'] = this.mediaInfo['dataId'];
			this.media['taskId'] = this.mediaInfo['taskId'];
			this.media['type'] = this.mediaInfo['type'];
			
			// console.log("https://api.juexinpsy.com",this.mediaInfo)
			axios.post("https://xqt.juexingongshe.com/course/detail",this.media).then((res)=>{
				console.log("提交数据",res)
			});
			wx.miniProgram.postMessage({data:{
				isMoment:this.isMoment
			}})
			// wx.miniProgram.switchTab({
			// 	url: '/pages/course/task-video-detail/index?id='+this.media.taskId+"&isMoment="+this.isMoment, //小程序必须有该目录
			// })
		},
	},
    watch: {
        option: {
            handler(newVal) {
				let _this = this;
				Artplayer.TOUCH_MOVE_RATIO = 0;
                this.instance = new Artplayer({
                    ...newVal,
                    customType: {
                        m3u8: playM3u8,
                    },
                    container: this.$refs.artRef,
                    plugins: [],
					fullscreen: true,
					// fullscreenWeb: true,
					// autoplay: true,
					pip: false,
					flip:true,
					autoOrientation:true,
                });
				this.instance.allowsPlaybackBufferFull = false
                this.$nextTick(() => {
                    this.$emit("get-instance", this.instance);
                });
				
				this.instance.on('ready', () => {
					//获取视频时长
					//设置初始化时间
					if (_this.seek < 1 ) {
						this.instance.seek = _this.mediaInfo.point;
					}
					_this.currentTime = _this.mediaInfo.point;
					this.media['long'] = Math.floor(this.instance.duration);
					if (_this.duration < 1) _this.duration = this.instance.duration;
				});
				// 当播放器暂停时触发
				this.instance.on('pause', () => {
					_this.currentTime = _this.instance.currentTime;
					_this.ajaxData();
				});
				//当前播放时间
				this.instance.on('video:timeupdate', () => {
					_this.currentTime = _this.instance.currentTime;
				});
				//视频停止播放，因为 media 已经到达结束点
				this.instance.on('video:ended', () => {
					_this.currentTime = _this.instance.currentTime;
					_this.ajaxData();
				});
				//播放已暂停
				this.instance.on('video:pause', () => {
					_this.currentTime = _this.instance.currentTime;
					_this.ajaxData();
				});
				this.instance.on('play',()=>{
					//是否在底部控制栏里显示播放器 窗口全屏 按钮
					_this.instance.fullscreen = true;
					//是否在底部控制栏里显示播放器 网页全屏 按钮
					_this.instance.fullscreenWeb = true;
					//是否在移动端的网页全屏时，根据视频尺寸和视口尺寸，旋转播放器
					_this.instance.autoOrientation = true;
				});
            },
            deep: true,
        }
    },
    mounted() {
		this.media = this.mediaInfo;
        console.log('object :>> ', this.option);
    },
    beforeDestroy() {
        if (this.instance && this.instance.destroy) {
            this.instance.destroy(false);
        }
    }
};
</script>
<style >
	/* .art-progress {
	    pointer-events: none !important;
	} */
	.art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-played {
		background-color: #16B99A !important;
	}
	.art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-indicator {
		background-color: #fff !important;
	}
</style>